import request from "@/utils/request";

export const alipayCallbackPay = (data, appName) =>
  request({
    url: "/huanxinxiang-service/v1/alipay-callback-pay/",
    method: "post",
    headers: {
      "App-Name": appName,
    },
    data,
  });
